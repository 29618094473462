<template>
  <v-card class="welcome-card docs-card">
    <v-card-title>
      <v-col>
        <v-row class="justify-center headline">
          iAdOps
        </v-row>

        <v-row class="justify-center body-2 coolgray--text">
          User Docs
        </v-row>

        <v-row class="justify-center body-2 coolgray--text">
          version {{ version }}
        </v-row>
      </v-col>
    </v-card-title>

    <v-card-text class="pb-6">
      Use the guides below to learn more about iAdOps.
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('layout')

export default {
  name: 'WelcomeCard',
  computed: {
    ...mapState({
      version: (state) => state.version,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.welcome-card {
  text-align: center;

  img {
    margin-bottom: 60px;
    max-width: 500px;
    width: 20vw;
  }
}
</style>
